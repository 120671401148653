import React from "react"
import Layout from "~/components/common/Layout"
import Quiz from "~/components/page/Quiz"
import { getHeaderTitles, getNextQuizLink, getNextTitle, getPreviousQuizLink } from "~/services/pages"
import withProgress from "~/services/withProgress"

const Page = ({ myPage = 1, mySubpage = 4 }) => {


  const questionNumber = 5
  const nextLink = getNextQuizLink(myPage, mySubpage, questionNumber)

  return (
    <Layout
      header={getHeaderTitles(myPage, mySubpage)}
      footer={{
        backLink: `${getPreviousQuizLink(myPage, mySubpage, questionNumber)}`,
        nextText: `NEXT: ${getNextTitle(myPage, mySubpage)}`,
      }}
    >
      <Quiz
        quizNumber={"ONE"}
        questionNumber={questionNumber}
        questionsTotal={5}
        question={"Why is it important to show athletes we see them?"}
        answers={[
          "It shows that we care about their individuality",
          "It motivates them to show up",
          "It shows we are noticing them and want to understand them",
          "All of the above",
        ]}
        correctAnswerNumber={3}
        answerDetail={
          "It’s up to coaches to pay attention to the details of these behaviors on and off the field in order to truly understand your players and build the strongest relationship possible."
        }
        nextLink={nextLink}
        nextText={"RESULTS"}
      >
      </Quiz>
    </Layout>
  )
}

export default withProgress(Page, 1, 4)
